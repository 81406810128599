<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    msg?: string | string[]
  }>()

  const text = computed(() => {
    // null / empty string / empty array
    if (!props.msg || props.msg.length === 0) {
      return null
    }

    // array
    if (Array.isArray(props.msg)) {
      return props.msg[0]
    }

    // string
    return props.msg
  })
</script>

<template>
  <app-notice
    v-if="text"
    type="warning"
    class="error-msg mt-2"
  >
    <!-- error-message class is used in api.ts scrolltoview on error 422 -->
    {{ text }}
  </app-notice>
</template>
