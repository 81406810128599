import type { RouteRecordRaw } from 'vue-router'

const adminRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/pages/customer/page-list.vue'),
    name: 'customer list',
    meta: { title: 'List Customer' },
  },
]

export default adminRoutes
