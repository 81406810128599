<script setup lang="ts">
  import TheToast from './components/the-toast.vue'
  import { useDashboard } from './models/dashboard'
  import { useAuthStore } from './stores/auth'

  const auth = useAuthStore()

  const { init } = useDashboard()
  init()
</script>

<template>
  <app-router-view v-if="auth.authenticated >= 0" />
  <the-toast />
  <!-- <error-maintenance v-if="app.maintenance" /> -->
</template>

<style lang="postcss" scoped></style>
