import type { RouteRecordRaw } from 'vue-router'

const expenseRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/pages/expense/list/page-list.vue'),
    name: 'expense list',
    meta: { title: 'List Pengeluaran' },
  },
  {
    path: 'tambah',
    component: () => import('@/pages/expense/create/page-create.vue'),
    name: 'expense create',
    meta: { title: 'Tambah Pengeluaran' },
  },
  {
    path: ':id',
    component: () => import('@/pages/expense/detail/page-show.vue'),
    props: true,
    name: 'expense show',
    meta: { title: 'Info Pengeluaran' },
  },
]

export default expenseRoutes
