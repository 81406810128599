import type { RouteRecordRaw } from 'vue-router'

const invoiceRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/pages/invoice/list/page-list.vue'),
    name: 'invoice list',
    meta: { title: 'List Invoice' },
  },
  {
    path: 'tambah',
    component: () => import('@/pages/invoice/create/page-create.vue'),
    name: 'invoice create',
    meta: { title: 'Tambahkan Invoice' },
  },
  {
    path: ':id',
    component: () => import('@/pages/invoice/detail/page-show.vue'),
    name: 'invoice show',
    props: true,
    meta: { title: 'Info Invoice' },
  },
]

export default invoiceRoutes
