import type { App } from 'vue'
import AppAccordion from './app-accordion.vue'
import AppEmpty from './app-empty.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppFormSection from './app-form-section.vue'
import AppForm from './app-form.vue'
import AppImg from './app-image.vue'
import AppLoadingDot from './app-loading-dot.vue'
import AppNotice from './app-notice.vue'
import AppPagination from './app-pagination.vue'
import AppRouterView from './app-router-view.vue'
import AppSearchPage from './app-search-page.vue'
import AppSwitch from './app-switch.vue'
import AppUserPhoto from './app-user-photo.vue'
import UseButton from './button'
import UseCard from './card'
import AppUserPhotoCropper from './cropper/app-user-photo-cropper.vue'
import UseDropdown from './dropdown'
import FaIcon from './fa-icon.vue'
import UseInput from './input'
import UseModal from './modal'
import AppSubmenuItem from './submenu/app-submenu-item.vue'
import AppSubmenu from './submenu/app-submenu.vue'
import AppTabItem from './tab/app-tab-item.vue'
import AppTab from './tab/app-tab.vue'
import AppTableEmpty from './table/app-table-empty.vue'
import AppTable from './table/app-table.vue'

export default {
  install(app: App) {
    app.use(UseButton)
    app.use(UseCard)
    app.use(UseDropdown)
    app.use(UseInput)
    app.use(UseModal)

    app.component('AppAccordion', AppAccordion)
    app.component('AppEmpty', AppEmpty)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppFormSection', AppFormSection)
    app.component('AppForm', AppForm)
    app.component('AppImg', AppImg)
    app.component('AppLoadingDot', AppLoadingDot)
    app.component('AppNotice', AppNotice)
    app.component('AppPagination', AppPagination)
    app.component('AppRouterView', AppRouterView)
    app.component('AppSearchPage', AppSearchPage)
    app.component('AppSwitch', AppSwitch)
    app.component('AppUserPhoto', AppUserPhoto)
    app.component('AppUserPhotoCropper', AppUserPhotoCropper)
    app.component('FaIcon', FaIcon)
    app.component('AppTabItem', AppTabItem)
    app.component('AppTab', AppTab)
    app.component('AppTableEmpty', AppTableEmpty)
    app.component('AppTable', AppTable)
    app.component('AppSubmenuItem', AppSubmenuItem)
    app.component('AppSubmenu', AppSubmenu)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppAccordion: typeof AppAccordion
    AppEmpty: typeof AppEmpty
    AppErrorMsg: typeof AppErrorMsg
    AppFormSection: typeof AppFormSection
    AppForm: typeof AppForm
    AppImg: typeof AppImg
    AppLoadingDot: typeof AppLoadingDot
    AppNotice: typeof AppNotice
    AppPagination: typeof AppPagination
    AppRouterView: typeof AppRouterView
    AppSearchPage: typeof AppSearchPage
    AppSwitch: typeof AppSwitch
    AppUserPhoto: typeof AppUserPhoto
    AppUserPhotoCropper: typeof AppUserPhotoCropper
    FaIcon: typeof FaIcon
    AppTabItem: typeof AppTabItem
    AppTab: typeof AppTab
    AppTableEmpty: typeof AppTableEmpty
    AppTable: typeof AppTable
    AppSubmenuItem: typeof AppSubmenuItem
    AppSubmenu: typeof AppSubmenu
  }
}
