import type { RouteRecordRaw } from 'vue-router'

const authRoutes: RouteRecordRaw = {
  path: '/',
  component: () => import('@/layouts/auth/page-layout.vue'),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/pages/auth/page-login.vue'),
      meta: { title: 'Login' },
    },
    {
      path: '/lupa-password',
      name: 'forget password',
      component: () => import('@/pages/auth/page-forget-password.vue'),
      meta: { title: 'Lupa Password' },
    },
  ],
}

export default authRoutes
