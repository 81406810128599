import Cropper from 'cropperjs'
import { ref } from 'vue'

export const useImgCropper = () => {
  const cropper = ref<Cropper>()
  const canvasWidth = ref(0)
  const canvasHeight = ref(0)
  const scaleX = ref(1)
  const scaleY = ref(1)
  const ready = ref(false)

  const init = (
    el: HTMLImageElement,
    width: number,
    height: number,
    canvas?: Cropper.CanvasData,
    preview?: string,
  ) => {
    ready.value = false
    canvasWidth.value = width
    canvasHeight.value = height

    cropper.value = new Cropper(el, {
      dragMode: 'move',
      cropBoxMovable: false,
      cropBoxResizable: false,
      aspectRatio: width / height,
      guides: false,
      viewMode: 0,
      preview: preview,
      // autoCropArea: 1,
      // minCanvasHeight: height,
      // minCanvasWidth: width,
      minCropBoxWidth: width,
      minCropBoxHeight: height,
      // responsive: false,
      checkOrientation: false,
      checkCrossOrigin: false,
      ready: () => {
        const container = document.querySelector<HTMLElement>('.cropper-container')
        if (!container) return
        // container.style.height = `${height}px`
        container.style.width = `100%`
        container.style.maxWidth = `${width}px`
        container.style.aspectRatio = (width / height).toString()
        if (canvas) {
          cropper.value?.setCanvasData(canvas)
        } else {
          autoFitFill()
        }
      },
    })
  }

  // watchEffect(() => {
  //   if (ready.value) {
  //   }
  // })

  const save = () => {
    return cropper.value?.getCroppedCanvas().toDataURL() ?? ''
  }

  // image manipulations
  const destroy = () => {
    if (!cropper.value) return
    cropper.value.destroy()
  }
  const rotate = (degree: number) => {
    if (!cropper.value) return
    cropper.value.rotate(degree)
  }
  const flipHorizontal = () => {
    if (!cropper.value) return
    scaleX.value *= -1
    cropper.value.scale(scaleX.value, scaleY.value)
  }
  const flipVertical = () => {
    if (!cropper.value) return
    scaleY.value *= -1
    cropper.value.scale(scaleX.value, scaleY.value)
  }
  const alignHorizontalLeft = () => {
    if (!cropper.value) return
    cropper.value.setCanvasData({ left: 0 })
  }
  const alignHorizontalCenter = () => {
    if (!cropper.value) return
    const cropBoxData = cropper.value.getCropBoxData()
    const canvasData = cropper.value.getCanvasData()
    const left = (cropBoxData.width - canvasData.width) / 2
    cropper.value.setCanvasData({ left })
  }
  const alignHorizontalRight = () => {
    if (!cropper.value) return
    const cropBoxData = cropper.value.getCropBoxData()
    const canvasData = cropper.value.getCanvasData()
    const left = cropBoxData.width - canvasData.width
    cropper.value.setCanvasData({ left })
  }
  const alignVerticalTop = () => {
    if (!cropper.value) return
    cropper.value.setCanvasData({ top: 0 })
  }
  const alignVerticalMiddle = () => {
    if (!cropper.value) return
    const cropBoxData = cropper.value.getCropBoxData()
    const canvasData = cropper.value.getCanvasData()
    const top = (cropBoxData.height - canvasData.height) / 2
    cropper.value.setCanvasData({ top })
  }
  const alignVerticalBottom = () => {
    if (!cropper.value) return
    const cropBoxData = cropper.value.getCropBoxData()
    const canvasData = cropper.value.getCanvasData()
    const top = cropBoxData.height - canvasData.height
    cropper.value.setCanvasData({ top })
  }
  const fitToHeight = () => {
    if (!cropper.value) return
    const { height } = cropper.value.getCropBoxData()
    cropper.value.setCanvasData({ height: Math.max(height) })
    alignVerticalTop()
  }
  const fitToWidth = () => {
    if (!cropper.value) return
    const { width } = cropper.value.getCropBoxData()
    cropper.value.setCanvasData({ width: Math.max(width) })
    alignHorizontalLeft()
  }
  const autoFitFill = () => {
    if (!cropper.value) return
    const { width, height } = cropper.value.getCanvasData()
    const aspectRatio = canvasWidth.value / canvasHeight.value
    if (width / height < aspectRatio) {
      fitToWidth()
    } else {
      fitToHeight()
    }
    alignVerticalMiddle()
    alignHorizontalCenter()
  }
  const autoFitContain = () => {
    if (!cropper.value) return
    const { width, height } = cropper.value.getCanvasData()
    const aspectRatio = canvasWidth.value / canvasHeight.value
    if (width / height > aspectRatio) {
      fitToWidth()
    } else {
      fitToHeight()
    }
    alignVerticalMiddle()
    alignHorizontalCenter()
  }

  return {
    cropper,
    init,
    save,
    destroy,
    rotate,
    flipHorizontal,
    flipVertical,
    alignHorizontalLeft,
    alignHorizontalCenter,
    alignHorizontalRight,
    alignVerticalTop,
    alignVerticalMiddle,
    alignVerticalBottom,
    fitToHeight,
    fitToWidth,
    autoFitFill,
    autoFitContain,
  }
}

export const base64ToBlob = async (base64Image: string) => {
  const res = await fetch(base64Image)
  return await res.blob()
}
