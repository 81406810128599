<script setup lang="ts"></script>

<template>
  <tr>
    <td
      colspan="99"
      class="h-48 text-center text-gray"
    >
      Belum ada data...
    </td>
  </tr>
</template>
