import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { useAppStore } from '@/stores/app'
import adminRoutes from './admin'
import authRoutes from './auth'
import branchRoutes from './branch'
import customerRoutes from './customer'
import dashboardRoutes from './dashboard'
import expenseRoutes from './expense'
import expenseCategoryRoutes from './expense-category'
import invoiceRoutes from './invoice'
import invoicePaymentRoutes from './invoice-payment'
import notificationRoutes from './notification'
import paymentMethodRoutes from './payment-method'
import roleRoutes from './role'

const routes: RouteRecordRaw[] = [
  layoutApp('/', dashboardRoutes),
  layoutApp('/pengguna-internal', adminRoutes),
  layoutApp('/tim', branchRoutes),
  layoutApp('/hak-akses', roleRoutes),
  layoutApp('/notifikasi', notificationRoutes),

  layoutApp('/tim/:branch/customer', customerRoutes),
  layoutApp('/tim/:branch/kategori-pengeluaran', expenseCategoryRoutes),
  layoutApp('/tim/:branch/pengeluaran', expenseRoutes),
  layoutApp('/tim/:branch/invoice', invoiceRoutes),
  layoutApp('/tim/:branch/pembayaran-invoice', invoicePaymentRoutes),
  layoutApp('/tim/:branch/metode-pembayaran', paymentMethodRoutes),

  authRoutes,
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: () => {
    return {
      top: 0,
      behavior: 'smooth',
    }
  },
})

router.beforeEach((to, _, next) => {
  const app = useAppStore()

  if (!to.meta.title) {
    throw new Error('Page must have a title.')
  }

  app.setPageTitle(to.meta.title)

  next()
})

export default router

function layoutApp(path: string, children: RouteRecordRaw[]): RouteRecordRaw {
  return {
    path,
    children,
    component: () => import('@/layouts/app/layout-app.vue'),
  }
}

declare module 'vue-router' {
  interface RouteMeta {
    title: string
  }
}
