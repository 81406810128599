<script setup lang="ts">
  // import { AnchorHTMLAttributes, ButtonHTMLAttributes, computed } from 'vue'
  import { computed } from 'vue'
  import type { ButtonHTMLAttributes } from 'vue'
  import type { RouteLocationRaw } from 'vue-router'

  // interface Props extends ButtonHTMLAttributes, AnchorHTMLAttributes {
  interface Props {
    to?: RouteLocationRaw
    loading?: boolean
    disabled?: boolean
    type?: ButtonHTMLAttributes['type']
    href?: string
  }

  const props = defineProps<Props>()

  const is = computed(() => {
    if (props.disabled || props.loading) {
      return 'button'
    }
    if (props.to) {
      return 'router-link'
    }
    if (props.href) {
      return 'a'
    }

    return 'button'
  })

  // render as link when `to` is provided, and props.disabled == false
  // otherwise, render as button
</script>

<template>
  <component
    :is="is"
    :to="to"
    :target="href ? '_blank' : undefined"
    :type="type ?? 'button'"
    :disabled="disabled || loading"
    class="disabled:cursor-not-allowed"
  >
    <slot></slot>
    <app-loading-dot v-if="loading" />
  </component>
</template>
