import type { RouteRecordRaw } from 'vue-router'

const adminRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/pages/admin/page-list.vue'),
    name: 'admin list',
    meta: { title: 'List Pengguna Internal' },
  },
]

export default adminRoutes
