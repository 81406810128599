<script setup lang="ts">
  import { debouncedWatch } from '@vueuse/core'
  import 'cropperjs/dist/cropper.css'
  import { ref } from 'vue'
  import { uiAvatarSrc } from '@/functions/user-photo'
  import AppImageCropper from './app-image-cropper.vue'

  const props = defineProps<{
    src?: string | null
    userName?: string
  }>()

  const emit = defineEmits<{
    (e: 'update:src', value?: string): void
    (e: 'cancel'): void
  }>()

  const size = 256
  const defaultUserName = 'Gradin'
  const defaultSrc = ref(uiAvatarSrc(props.userName ? props.userName : defaultUserName))
  debouncedWatch(
    () => props.userName,
    () => {
      defaultSrc.value = uiAvatarSrc(props.userName ? props.userName : defaultUserName)
    },
    { debounce: 1000 },
  )

  const modal = ref<InstanceType<typeof AppImageCropper>>()
  const inputFileImage = ref<HTMLInputElement>()

  const uncroppedSrc = ref('')
  const canvasData = ref<Cropper.CanvasData>()
  const imageChange = async (file: File[]) => {
    if (!file[0]) return
    modal.value?.open(URL.createObjectURL(file[0]))
  }
  const onSave = (cropped: string, original: string, canvas?: Cropper.CanvasData) => {
    emit('update:src', cropped)
    uncroppedSrc.value = original
    canvasData.value = canvas
  }
  const cancelChange = () => {
    emit('cancel')
    if (inputFileImage.value) {
      inputFileImage.value.value = ''
    }
  }
</script>

<template>
  <div>
    <app-image-cropper
      ref="modal"
      :width="size"
      :height="size"
      :cropped-src="src ?? undefined"
      @save="onSave"
    />

    <app-input-file
      v-slot="{ open }"
      accept="image/jpg, image/jpeg, image/png"
      @change="imageChange"
    >
      <div class="mb-2">
        <app-button
          class="max-w-64 w-full"
          @click="open()"
        >
          <app-img
            :src="src ?? defaultSrc"
            class="aspect-square max-h-64 max-w-64 border rounded-full"
          />
        </app-button>
      </div>
    </app-input-file>

    <div
      v-if="src && !src.startsWith('http')"
      class="max-w-64 w-full flex flex-col items-center space-y-2"
    >
      <app-button
        class="btn-primary w-28"
        @click="modal?.open(uncroppedSrc, canvasData)"
      >
        Edit Foto
      </app-button>
      <app-button
        class="btn-low-priority w-28"
        @click="cancelChange"
      >
        Hapus Foto
      </app-button>
    </div>
  </div>
</template>

<style lang="postcss">
  .cropper-view-box,
  .cropper-face {
    @apply rounded-full;
  }
</style>
