import { defineStore } from 'pinia'

const APP_TITLE = import.meta.env.VITE_APP_TITLE ?? 'Panel Admin'
type SidebarInfo = {
  count_project_request: number
}
type StorageInfo = {
  sum_usage: number
  count_files: number
}
type State = {
  title: string
  userDraggingFile: boolean
  sidebar: SidebarInfo
  storage: StorageInfo
  maintenance: boolean
}

export const useAppStore = defineStore('Page', {
  state: (): State => ({
    title: '',
    userDraggingFile: false,
    sidebar: {
      count_project_request: 0,
    },
    storage: {
      sum_usage: 0,
      count_files: 0,
    },
    maintenance: false,
  }),
  actions: {
    setPageTitle(title: string) {
      this.title = title
      document.title = `${title} | ${APP_TITLE}`
    },
    setUserDraggingFile(userDraggingFile: boolean) {
      this.userDraggingFile = userDraggingFile
    },
    setSidebar(value: SidebarInfo) {
      this.sidebar = value
    },
    setStorage(value: StorageInfo) {
      this.storage = value
    },
    setMaintenance(value: boolean) {
      this.maintenance = value
    },
  },
})
