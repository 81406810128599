import type { RouteRecordRaw } from 'vue-router'

const expenseCategoryRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/pages/expense-category/page-list.vue'),
    name: 'expense category list',
    meta: { title: 'List Kategori Pengeluaran' },
  },
]

export default expenseCategoryRoutes
