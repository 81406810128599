import { onClickOutside } from '@vueuse/core'

export default {
  // mounted(el: HTMLElement, binding: { value: () => void }) {
  mounted(el: HTMLElement, binding: { value: () => void }) {
    onClickOutside(el, () => {
      binding.value()
    })
  },
}
