import type { App } from 'vue'
import AppButtonGroup from './app-button-group.vue'
import AppButton from './app-button.vue'
import AppSubmit from './app-submit.vue'

export default {
  install(app: App) {
    app.component('AppButtonGroup', AppButtonGroup)
    app.component('AppButton', AppButton)
    app.component('AppSubmit', AppSubmit)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppButtonGroup: typeof AppButtonGroup
    AppButton: typeof AppButton
    AppSubmit: typeof AppSubmit
  }
}
