import type { RouteRecordRaw } from 'vue-router'

const paymentMethodRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/pages/payment-method/page-list.vue'),
    name: 'payment method list',
    meta: { title: 'List Metode Pembayaran' },
  },
]

export default paymentMethodRoutes
