<script setup lang="ts">
  import { computed, ref } from 'vue'
  import type { SelectHTMLAttributes } from 'vue'
  import { randomId } from '@/functions'

  const modelValue = defineModel<SelectHTMLAttributes['value']>()

  const props = defineProps<{
    id?: string
    icon?: string
    disabled?: boolean
    label?: string
    required?: boolean
    error?: string | string[]
    placeholder?: string
    loading?: boolean
  }>()

  const focus = ref(false)

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)
</script>

<template>
  <div>
    <label
      v-if="label"
      :for="inputId"
      class="form-label"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger-600"
      >
        *
      </span>
    </label>
    <div
      class="relative flex items-center border rounded pl-1"
      :class="{ 'input-ring': focus }"
    >
      <div
        class="flex flex-shrink-0 items-center justify-center text-gray"
        :class="{ 'w-6': !!icon, 'w-1': !icon }"
      >
        <fa-icon
          v-if="icon"
          :icon="icon"
        />
      </div>
      <select
        :id="inputId"
        v-model="modelValue"
        :required="required"
        :disabled="disabled"
        @focus="focus = true"
        @blur="focus = false"
      >
        <option
          v-if="placeholder"
          :value="null"
          hidden
        >
          {{ placeholder }}
        </option>
        <slot></slot>
      </select>

      <div class="pointer-events-none absolute right-2 z-10 flex items-center text-xs text-gray">
        <fa-icon
          v-if="loading"
          icon="i-far-circle-notch"
          class="animate-spin"
        />
        <fa-icon
          v-else
          icon="i-far-angle-down"
        />
      </div>
    </div>
    <app-error-msg :msg="error" />
  </div>
</template>

<style lang="postcss" scoped>
  /* border on the container div, so that the focus ring enclose the icon as well */
  /* then the select height should less than h-9 */
  select {
    @apply h-8.5 block flex-1 cursor-pointer appearance-none rounded pl-2 pr-7 text-sm text-gray-700 focus-visible:ring-0;
  }
</style>
