import { defineStore } from 'pinia'

type State = {
  user: AuthenticatedUser
  authenticated: -1 | 0 | 1 // 0 = false, 1 = true, -1 = unknown
}

export const useAuthStore = defineStore('Auth', {
  state: (): State => ({
    user: {
      id: 0,
      name: '',
      phone: '',
      email: '',
      permissions: [],
      branches: [],
      role: null,
      photo_url: null,
      photo_url_xs: null,
    },
    authenticated: -1,
  }),
  getters: {
    userCan: (state) => (permission: string) => {
      return state.user?.permissions.some((p) => p.toLowerCase() === permission.toLowerCase())
    },
  },
  actions: {
    setUser(user: Partial<AuthenticatedUser>) {
      this.user = { ...this.user, ...user }
    },
    setEmail(email: string) {
      this.user.email = email
    },
    setAuthenticated(authenticated: -1 | 0 | 1) {
      this.authenticated = authenticated
    },
    setBranches(branches: Branch[]) {
      this.user.branches = branches
    },
  },
})
