import { useRoute, useRouter } from 'vue-router'
import { useApi } from '@/functions/api'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

export function useDashboard() {
  const route = useRoute()
  const router = useRouter()
  const api = useApi()

  const auth = useAuthStore()
  const app = useAppStore()

  type DashboardInitResponse = {
    user: AuthenticatedUser
    storage: {
      sum_usage: number
      count_files: number
    }
  }

  const shouldRedirectToHome = () => {
    // route where authenticated user should not be able to access
    return ['login', 'forget password', 'forbidden'].includes(route.name as string)
  }

  const init = async () => {
    try {
      const response = await api.GET<DashboardInitResponse>('admin/app')
      auth.setUser(response.user)
      app.setStorage(response.storage)

      // if (response.user.require_new_password) {
      //   await router.replace({ name: 'set new password' })
      // }

      if (shouldRedirectToHome()) {
        if (auth.user.branches.length === 1 && !route.query.r) {
          await router.replace({
            name: 'dashboard branch',
            params: { branch: auth.user.branches[0].id },
          })
        } else {
          await router.replace('/' + (route.query.r ?? ''))
        }
      }

      // when user authenticated but opening login page
      // settimeout to prevent flashing login page, let
      // the redirect above to take effect first
      auth.setAuthenticated(1)
    } catch (error) {
      setTimeout(() => {
        // when user not authenticated but opening page other than
        // login, forget password, forbidden, let the redirect
        // to login page take effect first
        auth.setAuthenticated(0)
      }, 200)
    }
  }

  return {
    init,
  }
}
