<script setup lang="ts">
  import { nextTick, ref } from 'vue'
  import { useImgCropper } from '@/functions/img'

  const props = defineProps<{
    width: number
    height: number
    croppedSrc?: string
  }>()

  const emit = defineEmits<{
    (e: 'save', croppedSrc: string, originalSrc: string, canvasData?: Cropper.CanvasData): void
  }>()

  const {
    cropper,
    init,
    save,
    rotate,
    flipHorizontal,
    flipVertical,
    alignHorizontalLeft,
    alignHorizontalCenter,
    alignHorizontalRight,
    alignVerticalTop,
    alignVerticalMiddle,
    alignVerticalBottom,
    fitToHeight,
    fitToWidth,
    autoFitFill,
    autoFitContain,
  } = useImgCropper()

  const modal = ref<AppModal>()
  const elImage = ref<HTMLImageElement>()
  const elImageSrc = ref('')

  const saveCrop = async () => {
    emit('save', save(), elImageSrc.value, cropper.value?.getCanvasData())
    close()
  }
  const open = (src: string, canvas?: Cropper.CanvasData) => {
    elImageSrc.value = src
    modal.value?.open()
    nextTick(() => {
      if (!elImage.value) return
      init(elImage.value, props.width, props.height, canvas)
    })
  }
  const close = () => {
    modal.value?.close()
  }

  defineExpose({
    open,
  })
</script>

<template>
  <app-modal
    ref="modal"
    title="Edit Foto"
  >
    <div>
      <div class="mx-auto size-64 max-w-full">
        <img
          ref="elImage"
          :src="elImageSrc"
          class="block max-w-full rounded"
        />
      </div>
    </div>

    <div class="my-8 space-y-4">
      <div class="flex-center">
        <app-button
          class="btn-low-priority btn-icon mr-1"
          title="Rotate Left"
          @click="rotate(-90)"
        >
          <fa-icon icon="i-far-rotate-left" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-4"
          title="Rotate Right"
          @click="rotate(90)"
        >
          <fa-icon icon="i-far-rotate-right" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Flip Horizontal"
          @click="flipHorizontal"
        >
          <fa-icon icon="i-far-arrow-right-arrow-left" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-4 rotate-90"
          title="Flip Vertical"
          @click="flipVertical"
        >
          <fa-icon icon="i-far-arrow-right-arrow-left" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Fit To Width"
          @click="fitToWidth"
        >
          <fa-icon icon="i-far-arrows-left-right-to-line" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-4 rotate-90"
          title="Fit To Height"
          @click="fitToHeight"
        >
          <fa-icon icon="i-far-arrows-left-right-to-line" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Auto Fit (Contain)"
          @click="autoFitContain"
        >
          <fa-icon icon="i-far-compress" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Auto Fit (Fill)"
          @click="autoFitFill"
        >
          <fa-icon icon="i-far-expand" />
        </app-button>
      </div>

      <div class="flex-center">
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Align Horizontal Left"
          @click="alignHorizontalLeft"
        >
          <fa-icon icon="i-far-objects-align-left" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Align Horizontal Center"
          @click="alignHorizontalCenter"
        >
          <fa-icon icon="i-far-objects-align-center-horizontal" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-4"
          title="Align Horizontal Right"
          @click="alignHorizontalRight"
        >
          <fa-icon icon="i-far-objects-align-right" />
        </app-button>

        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Align Vertical Top"
          @click="alignVerticalTop"
        >
          <fa-icon icon="i-far-objects-align-top" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority mr-1"
          title="Align Vertical Middle"
          @click="alignVerticalMiddle"
        >
          <fa-icon icon="i-far-objects-align-center-vertical" />
        </app-button>
        <app-button
          class="btn-icon btn-low-priority"
          title="Align Vertical Bottom"
          @click="alignVerticalBottom"
        >
          <fa-icon icon="i-far-objects-align-bottom" />
        </app-button>
      </div>
    </div>

    <app-modal-footer>
      <app-button
        class="btn-primary"
        @click="saveCrop"
      >
        Simpan
      </app-button>
      <app-button
        class="btn-low-priority"
        @click="close"
      >
        Batal
      </app-button>
    </app-modal-footer>
  </app-modal>
</template>

<style lang="postcss" scoped>
  .btn-icon {
    @apply flex-shrink-0 !p-0 !text-xs !size-7 sm:!size-9;
  }
</style>
