import type { RouteRecordRaw } from 'vue-router'

const invoicePaymentRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/pages/invoice-payment/list/page-list.vue'),
    name: 'invoice payment list',
    meta: { title: 'List Pembayaran Invoice' },
  },
  {
    path: 'tambah',
    component: () => import('@/pages/invoice-payment/create-edit/page-create-edit.vue'),
    name: 'invoice payment create',
    meta: { title: 'Tambahkan Pembayaran Invoice' },
  },
  {
    path: ':id',
    component: () => import('@/pages/invoice-payment/create-edit/page-create-edit.vue'),
    name: 'invoice payment edit',
    props: true,
    meta: { title: 'Edit Pembayaran Invoice' },
  },
]

export default invoicePaymentRoutes
