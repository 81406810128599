import type { RouteRecordRaw } from 'vue-router'

const notificationRoutes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('@/pages/notification/page-list.vue'),
    name: 'notification list',
    meta: { title: 'List Notifikasi' },
  },
]

export default notificationRoutes
