<script setup lang="ts">
  import { computed, ref } from 'vue'

  const content = ref<HTMLDivElement>()
  const isOpen = defineModel<boolean>({ default: false })

  const height = computed(() => content.value?.scrollHeight ?? 0)

  const clicked = () => {
    isOpen.value = !isOpen.value
  }

  defineExpose({ clicked })
</script>

<template>
  <div
    ref="content"
    class="h-0 divide-y divide-gray-200 overflow-hidden duration-500 ease-in-out w-full"
    :style="{ height: isOpen ? height + 'px' : 0 }"
  >
    <slot></slot>
  </div>
</template>
