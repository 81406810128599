<script setup lang="ts">
  import { computed } from 'vue'
  import { randomId } from '@/functions'

  const props = defineProps<{
    submitting: boolean
    disabled?: boolean
    id?: string
  }>()

  const emit = defineEmits<{
    (e: 'submit'): void
    (e: 'cancel'): void
  }>()

  const defaultFormId = randomId()
  const formId = computed(() => props.id ?? defaultFormId)
</script>

<template>
  <form
    :id="formId"
    @submit.prevent="emit('submit')"
  >
    <slot></slot>

    <slot name="action">
      <div class="flex-center xl:justify-start space-x-4 xl:pl-64">
        <app-button
          class="btn-low-priority w-32"
          :disabled="submitting"
          @click="emit('cancel')"
        >
          Batal
        </app-button>
        <app-submit
          class="btn-primary w-32"
          :disabled="disabled"
          :loading="submitting"
        >
          Simpan
        </app-submit>
      </div>
    </slot>
  </form>
</template>

<style lang="postcss" scoped>
  :deep() section {
    @apply mb-8 border-b pb-8;
  }
</style>
