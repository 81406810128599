<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    type: 'danger' | 'warning' | 'info' | 'success'
  }>()

  const isDanger = computed(() => props.type === 'danger')
  const isWarning = computed(() => props.type === 'warning')
  const isInfo = computed(() => props.type === 'info')
</script>

<template>
  <div
    class="flex items-center rounded p-2 space-x-2"
    :class="{
      danger: isDanger,
      warning: isWarning,
      info: isInfo,
    }"
  >
    <span class="flex text-sm">
      <fa-icon
        v-if="isDanger"
        icon="i-fas-triangle-exclamation"
      />
      <fa-icon
        v-else-if="isWarning"
        icon="i-fas-diamond-exclamation"
      />
      <fa-icon
        v-else-if="isInfo"
        icon="i-fas-circle-info"
      />
    </span>
    <span class="text-xs font-medium">
      <slot></slot>
    </span>
  </div>
</template>

<style lang="postcss" scoped>
  .danger {
    @apply bg-danger-100 border-l-danger text-danger border-l-4;
  }
  .warning {
    @apply border-l-4 border-l-yellow-600 bg-yellow-100 text-yellow-600;
  }
  .info {
    @apply bg-info-100 border-l-info text-info border-l-4;
  }
</style>
