import type { RouteRecordRaw } from 'vue-router'

const dashboardRoutes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'dashboard',
    component: () => import('@/pages/home/page-home.vue'),
    meta: { title: 'Dashboard' },
  },
  {
    path: ':branch',
    name: 'dashboard branch',
    component: () => import('@/pages/home/page-home.vue'),
    meta: { title: 'Dashboard' },
    props: true,
  },
]

export default dashboardRoutes
